<template>
    <div class="flex justify-center items-center h-full">

        <div>

            <div class="text-xs  h-40 flex justify-center items-center rounded-sm py-2 px-2 w-full">
                <!-- Form as seen by candidate. -->
                <!-- {{form}} -->


            <neo-button
                text="click to preview candiate form"
                @click="previewForm"
            />

            </div>
        </div>
    </div>
</template>

<script>
// import axios from "@/axios";
import Button from "@/components/button";
import axios from "@/axios";

export default {
    name:"candidate-form-view",
    data(){
        return {
            form:{},
            role:{
                candidate:"106c1edb-4e6a-419c-a75f-255cf451d59e"
            }
        }
    },
    components:{
        "neo-button":Button,
    },
    async mounted(){
        await this.fetchForm()
    },
    methods:{
        async fetchForm(){
            const url = "/screening-package/5c69cb5c-76e4-4988-a897-faba70837bdb/form"
            const data = await axios.get(url)
            console.log(data.data)
        },
        previewForm(){
            const package_id = this.$route.params.packageName;
            const routeData = this.$router.resolve({
                name: "Form Builder",
                params: { role: this.role.candidate, package:package_id },
            });
            window.open(routeData.href, "_blank");


        }
    }
}
</script>

<style scoped>

</style>